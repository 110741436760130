<template>
  <!-- Image Right -->
  <div
    v-if="service.metadata.homepage_position === 'right'"
    class="container-fluid bg"
    :style="{
      'background-image': `linear-gradient(to left, ${service.metadata.service_background_gradient} )`,
    }"
  >
    <div class="row pt-5">
      <div
        class="col-md-6 pt-3"
        data-aos="zoom-out"
        data-aos-duration="1500"
        data-aos-delay="400"
        data-aos-once="true"
      >
        <h2 class="service-heading">
          {{ service.metadata.service }}<br />
          Restoration Services
        </h2>
        <p class="text-w">
          {{ service.metadata.home_page_service_summary }}
        </p>
      </div>
      <div
        class="col-md-6"
        data-aos="zoom-in"
        data-aos-duration="1500"
        data-aos-delay="400"
        data-aos-once="true"
      >
        <img
          :src="service.metadata.service_image.url"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
    <div class="row pt-5"></div>
  </div>
  <!-- Image Left -->
  <div
    v-else
    class="container-fluid bg"
    :style="{
      'background-image': `linear-gradient(to right, ${service.metadata.service_background_gradient} )`,
    }"
  >
    <div class="row pt-5">
      <div
        class="col-md-6"
        data-aos="zoom-in"
        data-aos-duration="1500"
        data-aos-delay="400"
        data-aos-once="true"
      >
        <img
          :src="service.metadata.service_image.url"
          alt=""
          class="img-fluid"
        />
      </div>
      <div
        class="col-md-6 pt-3"
        data-aos="zoom-out"
        data-aos-duration="1500"
        data-aos-delay="400"
        data-aos-once="true"
      >
        <h2 class="service-heading">
          {{ service.metadata.service }}<br />
          Restoration Services
        </h2>
        <p class="text-w">
          {{ service.metadata.home_page_service_summary }}
        </p>
      </div>
    </div>
    <div class="row pt-5"></div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";
export default {
  name: "HomePageService",
  props: { service: { type: Object, required: true } },
  setup(props) {
    const state = reactive({
      service: props.service,
    });
    return {
      state,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/main.scss";
.service-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  letter-spacing: 3px;
  font-weight: 700;

  color: white;
  text-transform: uppercase;
  transition: all 0.5s;
  // text-shadow: 2px 0 4px black;
  @include respond(tablet) {
    font-size: 30px;
    text-align: center;
  }
  @include respond(phone) {
    font-size: 24px;
  }
}

.service-ul {
  @include respond(tablet) {
    text-align: center;
  }
  @include respond(phone) {
    text-align: center;
    padding-left: 0;
  }
}

.service-ul li {
  display: inline-block;
  color: white;
  font-size: 20px;
  font-weight: 400;
  margin-right: 35px;
  transition: all 0.5s;
  line-height: 2;
}

.bg {
  min-height: 60vh;
}
</style>
