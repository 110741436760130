<template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-fluid pt-3">
      <a class="navbar-brand" href="#">
        <img class="nav-logo" src="../assets/img/uwrgLogo.png" alt="" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0"></ul>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="#">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Blog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Reviews</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/main.scss";
.navbar-brand {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 400;
  margin-left: 50px;
  transition: all 1s ease-in-out;
  //   animation: spinnow 5s infinite linear;

  &:hover {
    text-decoration: underline black;
  }
  @include respond(tablet) {
    margin-left: 10px;
  }
  @include respond(phone) {
    margin-left: 0;
  }
}
.navbar {
  background: transparent;
}
.navbar-nav > .nav-item {
  margin-left: 15px;
  margin-right: 50px;
  @include respond(tablet) {
    margin-right: 15px;
  }
  @include respond(phone) {
    margin-left: 0;
  }
}
.nav-item {
  font-size: 22px;
  font-weight: 500;
}
.nav-link {
  font-family: "Montserrat", sans-serif;
  color: white !important;
  text-shadow: 2px 0 4px black;
  transition: all 0.5s ease-in-out;
  &:hover {
    text-decoration: underline 2px $red;
    transform: scale(1.1);
  }
}

.nav-logo {
  width: 100px;
}
</style>
