<template>
  <div class="container-fluid footer">
    <div class="container">
      <div class="row pt-3 center-on-mobile">
        <div class="col-md-12 text-center">
          <a href="index.html"
            ><img
              class="uwrg-logo-footer"
              src="../assets/img/uwrgLogo.png"
              alt=""
          /></a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 pt-4 text-center">
          <ul class="footer-ul">
            <li><a href="" class="footer-a-tag">Home</a></li>
            <li><a href="" class="footer-a-tag">Services</a></li>
            <li><a href="" class="footer-a-tag">Blog</a></li>
            <li><a href="" class="footer-a-tag">Testimonials</a></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <p class="footer-heading">
            {{ content.office_address }}
          </p>
        </div>
      </div>

      <div class="row pt-4">
        <div class="col-12 text-center">
          <a class="social-media-badges" href=""
            ><img class="img-fluid" src="../assets/img/facebook-sm.png" alt=""
          /></a>
          <a class="social-media-badges" href=""
            ><img src="../assets/img/linkedin-sm.png" alt=""
          /></a>
          <a class="social-media-badges" href=""
            ><img src="../assets/img/instagram-sm.png" alt=""
          /></a>
          <a class="social-media-badges" href=""
            ><img src="../assets/img/twitter-sm.png" alt=""
          /></a>
          <a class="social-media-badges" href=""
            ><img src="../assets/img/youtube-sm.png" alt=""
          /></a>
        </div>
      </div>

      <div class="row pt-5"></div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, useContext } from "vue";
import { cosmicService } from "../services/cosmic";
import { AppState } from "../AppState";
export default {
  name: "Footer",
  setup() {
    return {
      content: computed(() => AppState.homepage),
    };
  },
};
</script>
<style scoped lang="scss">
@import "../assets/scss/main.scss";
.footer {
  background-color: black;
}

.uwrg-logo-footer {
  width: 100px;
  transition: all 0.5s;
  //   animation: spinnow 5s infinite linear;
  &:hover {
  }
}

.footer-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  color: white;
  font-weight: 400;
  @include respond(tablet) {
    text-align: center;
  }
}

.footer-a-tag {
  font-family: "Montserrat", sans-serif;
  //   text-decoration: none;
  font-size: 18px;
  color: white;
  font-weight: 300;
  line-height: 2;
  transition: all 0.5s;
  &:hover {
    color: red;
  }
}

.footer-ul {
  list-style: none;
}

.footer-ul li {
  display: inline-block;
  margin-right: 30px;
}

.social-media-badges {
  margin: 0 20px;
}

.social-media-badges img {
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
  }
}
</style>
