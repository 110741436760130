<template>
  <div
    class="container-fluid hero"
    :style="{
      'background-image': `linear-gradient( 90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.4) 35%,
    rgba(0, 0, 0, 0.4) 100%),   url(${content.hero_image.url})`,
    }"
  >
    <Navbar />
    <div class="row pt-5 hero-text-margin">
      <div data-aos="fade-right" data-aos-duration="1000" class="col-12">
        <h1 class="page-heading-uwrg">
          United Water Restoration Group <br />
          <span class="page-heading"> Of {{ content.office_title }}</span>
        </h1>
      </div>
    </div>
    <div class="row services-text-margin pt-4">
      <div class="col-12">
        <ul class="heading-ul">
          <li
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="500"
            data-aos-once="true"
            v-for="service in services"
            :service="service"
          >
            <img class="list" :src="service.metadata.service_icon.url" alt="" />
            {{ service.metadata.service }}
          </li>
        </ul>
      </div>
    </div>

    <div class="row pt-5 hero-text-margin">
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="2000"
        data-aos-once="true"
        class="col-12"
      >
        <a
          :href="'tel:' + content.a_tag_phone_number"
          class="styled-btn styled-btn--callNow"
        >
          Get Immediate Help Today {{ content.display_phone_number }}
        </a>
      </div>
    </div>
  </div>
  <div class="container-fluid black-bg">
    <div class="container">
      <div class="row text-center reviews-margin">
        <div class="col-md-3">
          <a :href="content.google_review_link">
            <img
              src="../assets/img/google.png"
              alt=""
              class="img-fluid reveiw-link"
            />
          </a>
        </div>
        <div class="col-md-3">
          <img
            src="../assets/img/yelp.png"
            alt=""
            class="img-fluid reveiw-link"
          />
        </div>
        <div class="col-md-3">
          <img
            src="../assets/img/facebook.png"
            alt=""
            class="img-fluid reveiw-link"
          />
        </div>
        <div class="col-md-3">
          <img
            src="../assets/img/granted.png"
            alt=""
            class="img-fluid reveiw-link"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row pt-5">
      <div class="col-md-6">
        <div class="card services-text-margin">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <h2 class="card-heading">Water Restoration,</h2>
                <h2 class="card-heading">Fire Restoration,</h2>
                <h2 class="card-heading">& Mold Removal Experts</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 center-on-mobile text-center">
        <p class="card-text">{{ content.main_content_top_paragraph }}</p>
        <button class="styled-btn styled-btn--callNow">
          Call Now: {{ content.display_phone_number }}
        </button>
      </div>
    </div>
  </div>
  <div class="container pt-5"></div>
  <HomePageService v-for="service in services" :service="service" />

  <div class="container-fluid">
    <div class="row pt-5 reverse-row-mobile">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="choose-us-heading">Why Choose Us?</h2>
            <p class="text">
              {{ content.why_choose_us_text_1 }}
            </p>
            <p class="text">
              {{ content.why_choose_us_text_2 }}
            </p>
          </div>
        </div>
        <div class="row pt-3 text-center">
          <div class="col-12">
            <a
              :href="'tel:' + content.a_tag_phone_number"
              class="styled-btn styled-btn--callNow"
            >
              Call Now! {{ content.display_phone_number }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <form>
          <div class="form-margin">
            <div class="row pt-4">
              <div class="col-12">
                <p class="form-label">Message</p>
                <textarea
                  placeholder="How Can We Help?"
                  class="form-control"
                  id="contact-message"
                  cols="5"
                  rows="5"
                  style="height: 150px"
                ></textarea>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-md-6">
                <p class="form-label">Name</p>
                <input
                  id="contact-name"
                  required
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-md-6">
                <p class="form-label">Phone Number</p>
                <input
                  id="contact-email"
                  required
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-md-6">
                <p class="form-label">Email Address</p>
                <input
                  id="contact-company"
                  required
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-md-6">
                <p class="form-label">Zip Code</p>
                <input
                  id="contact-role"
                  required
                  type="text"
                  class="form-control"
                />
              </div>
            </div>

            <div class="row pt-4">
              <div class="col-12 text-center">
                <button
                  id="form-button"
                  type="submit"
                  class="styled-btn styled-btn--form"
                >
                  Contact Now!
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container pt-5"></div>

  <Footer />
</template>

<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
import HomePageService from "../components/HomePageService.vue";
import { computed, onMounted, reactive, useContext } from "vue";
import { cosmicService } from "../services/cosmic";
import { AppState } from "../AppState";
export default {
  name: "Home",
  setup() {
    onMounted(async () => {
      await cosmicService.getHomePageContent();
    });
    return {
      content: computed(() => AppState.homepage),
      services: computed(() => AppState.services),
      hero_image: computed(
        () =>
          "https://www.google.com/url?sa=i&url=https%3A%2F%2Frestorationtechs.com%2F7-common-misconceptions-about-water-damage-repair%2F&psig=AOvVaw1j9s4cNvTypmUX_iHesHH_&ust=1646486233346000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCIDljqDKrPYCFQAAAAAdAAAAABAD"
      ),
    };
  },
  components: {
    Navbar,
    Footer,
    HomePageService,
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/main.scss";
.hero {
  // background-image: linear-gradient(
  //   90deg,
  //   rgba(0, 0, 0, 0.5) 0%,
  //   rgba(0, 0, 0, 0.4) 35%,
  //   rgba(0, 0, 0, 0.4) 100%
  // );

  // height: 70vh;
  // clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 150px;
}

.hero-text-margin {
  margin-left: 60px;
  @include respond(tablet) {
    margin-left: 0;
    text-align: center;
  }
}

.services-text-margin {
  margin-left: 25px;
  @include respond(tablet) {
    margin-left: 0;
  }
}
.heading-ul {
  list-style: none;
  @include respond(tablet) {
    text-align: center;
  }
  @include respond(phone) {
    text-align: center;
    padding-left: 0;
  }
}

.heading-ul li {
  display: inline-block;
  color: white;
  font-size: 24px;
  font-weight: 400;
  margin-right: 35px;
  text-shadow: 2px 0 6px black;
  transition: all 0.5s;
}

.list {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;
  top: -5px;
  left: 3%;
  padding-right: 5px;
}

// Google Facebook Yelp Granted
.black-bg {
  background-color: black;
}

.reviews-margin {
  padding: 25px 0;
}

.reveiw-link {
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

// Main Content Heading
.card {
  // height: 100%;
  // height: 30vh;
  padding: 0.5rem 0.5rem;
  border: none;
  background-image: linear-gradient(346deg, #064794, #fa0707);
  border-radius: 40px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.card-body {
  margin: 50px 25px;
}

.card-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  letter-spacing: 3px;
  font-weight: 700;
  line-height: 2;
  color: white;
  text-transform: uppercase;
  transition: all 0.5s;
  // text-shadow: 2px 0 4px black;
  @include respond(tablet) {
    font-size: 30px;
    text-align: center;
  }
  @include respond(phone) {
    font-size: 20px;
  }
}

.card-text {
  font-size: 28px;
  font-weight: 400;
  margin-top: 11%;
  @include respond(phone) {
    font-size: 22px;
  }
}

// Services

.services-left-margin {
  margin-left: 100px;
}

// Why choose us

.choose-us-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  letter-spacing: 3px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.5s;
  // text-shadow: 2px 0 4px black;
  @include respond(tablet) {
    font-size: 30px;
    text-align: center;
  }
  @include respond(phone) {
    font-size: 20px;
  }
}
</style>
